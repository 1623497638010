import { LOCATION_TYPE } from 'constants/api.constant';
import _ from 'lodash';
import moment from 'moment';


const constructUrlWithParams = (endpoint, option) => {
  let url = endpoint;
  _.each(_.keys(option), key => {
    url = url.replace(new RegExp(`:${key}`, 'g'), option[key]);
  });
  return url;
}

const generateSlug = (inputString) => {
  let slug = inputString.toLowerCase().replace(/\s+/g, '-');
  slug = slug.replace(/[^a-z0-9-]/g, '');
  return slug;
}

function hasDomain(url) {
  // eslint-disable-next-line
  const regex = /^(https?:\/\/)?([^\s\/]+\.[^\s\/]+)(\/[^\s]*)?$/;
  return regex.test(url);
}

const getDistinctValues = (data) => {
  const distinctValuesMap = new Map();
  data.forEach(item => {
    if (!distinctValuesMap.has(item.value) ||
      moment(item.created_at).isAfter(
        moment(distinctValuesMap.get(item.value).created_at)
      )
    ) {
      distinctValuesMap.set(item.value, item);
    }
  });
  return Array.from(distinctValuesMap.values()).sort(
    (a, b) => moment(a.created_at).diff(moment(b.created_at)
  ));
}

const roundingNumber = (value, numberOfDecimal = 1) => {
  return _.round(parseFloat(value), numberOfDecimal).toFixed(numberOfDecimal);
}

/**
 * Trim all the spacing between or at the beginning/end of a string
 */
const trimAll = text => {
  if (typeof text !== 'string') return text;
  return text.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
};

const formatAnimalName = text => {
  if (typeof text !== 'string' || text === '') return text;
  return text.slice(-4);
};

const formatTagId = text => {
  if (typeof text !== 'string' || text === '') return text;
  return text.replaceAll(':', '');
};

const getFirstLetter = text => {
  return _.join(_.map(_.words(text), word => _.upperCase(_.first(word))), '')
}

/**
 * Time utils
 */
const getLocalNow = () => {
  return moment()
}

const getMoment = (value) => {
  return moment(value);
}

const getMomentUnit = (value) => {
  return moment.unix(value)
}

const formatDate = (inputDate) => {
  return moment(inputDate).format('DD/MM/YYYY HH:mm');
}

const dateFormatForChart = (inputDate) => {
  if (!_.isUndefined(inputDate)) {
    return moment(inputDate).format('DD MMM YYYY');
  }
  return ''
}

const getDatesBetweenDates = (startDate, endDate, option = "days", step = 1) => {
  let now = startDate
  const dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now);
    now = now.clone().add(step, option);
  }
  return dates;
}

const formatTimestampQueryCube = (datetime) => {
  if (datetime) {
    return moment(datetime).format("YYYY-MM-DDTHH:mm:ss")
  }
}

const formatTimestampQueryBackend = (datetime) => {
  if (datetime) {
    return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss')
  }
}

const calculateWeeksOfAge = (date) => {
  return moment().diff(moment(date), 'weeks')
}

const getDateInfo = (datetime, timezone) => {
  datetime = moment(datetime).tz(timezone);
  const year = datetime.year();
  const day = datetime.date();
  const month = datetime.month()
  return { year, day, month };
}

const convertToUTCAndToTimezone = (val, timezone) => {
  return moment.utc(val).tz(timezone)
}

const getStartTimeQueryAnimal = (endTime) => {
  return endTime.clone().subtract(1, 'day').startOf("dates")
}

const generatePenTypeTitle = (currentLocation, locationSetting) => {
  if ((locationSetting && locationSetting.use_alphabetic_pen_names) ||
    currentLocation.type === LOCATION_TYPE.GROWER_FINISHER_ROOM ||
    currentLocation.type === LOCATION_TYPE.SOW_FARROWING ||
    currentLocation.type === LOCATION_TYPE.GENERAL
  ) {
    return 'global.txtZone'
  }
  return 'global.txtPen'
}

const createSortObject = (orderBy) => {
  const isDesc = orderBy.startsWith('-');
  const id = isDesc ? orderBy.slice(1) : orderBy;
  return [{
      id: id,
      desc: isDesc
  }];
}

const hasValueAboveYAxisThreshold = (arr) => {
  return arr.some(value => value > 4);
}

const calculateAverageByKey = (data, key) => {
  const values = _.chain(data)
      .map(item => parseFloat(_.get(item, key, NaN)))
      .filter(value => !isNaN(value))
      .value();
  const average = _.mean(values);
  return average ? average : 0;
}

export {
  constructUrlWithParams,
  generateSlug,
  formatDate,
  dateFormatForChart,
  getDatesBetweenDates,
  trimAll,
  formatAnimalName,
  formatTagId,
  formatTimestampQueryCube,
  formatTimestampQueryBackend,
  roundingNumber,
  calculateWeeksOfAge,
  getDistinctValues,
  getLocalNow,
  getDateInfo,
  getFirstLetter,
  hasDomain,
  convertToUTCAndToTimezone,
  getMoment,
  getMomentUnit,
  generatePenTypeTitle,
  createSortObject,
  getStartTimeQueryAnimal,
  hasValueAboveYAxisThreshold,
  calculateAverageByKey
}